.item_one[data-v-183adffe] .el-input__wrapper {
  width: 200px;
}
.item_one[data-v-183adffe] .el-date-editor {
  width: 200px;
}
.el-select[data-v-183adffe] {
  margin: 0px;
}
.el-form-item[data-v-183adffe] {
  margin-right: 0 !important;
}
.record[data-v-183adffe]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}